<div *ngIf="!isLoaded" class="lds-ring"><div></div><div></div><div></div><div></div></div>

<div class="project-holder desktop" [class.isLoad]="isLoaded">
  <div class="iframe-holder" >
    <iframe (load)="loadCallBack()" class="desktop"  width="100%" height="896" src="https://xd.adobe.com/embed/953dd6bc-692c-4ff3-99ab-71dade4a8d81-c06e/?fullscreen" frameborder="0" allowfullscreen></iframe>
  </div>
</div>
<div class="project-holder mobile" [class.isLoad]="isLoaded">
  <iframe (load)="loadCallBack()"  width="100%" height="896" src="https://xd.adobe.com/embed/62d339ac-6eaf-487e-993a-3dc90555c66c-3780/?fullscreen" frameborder="0" allowfullscreen></iframe>
</div>
<app-see-more
  link="/project/imaread"
  link2="/project/daisy"
  text="How can digital study tool optimize learning experience?"
  text2="How can we save our environment and make better shopping choices?"
  img2="dd.jpg"
  img="imaread.jpg"></app-see-more>
