<div class="contact">
  <h3>Say hello!</h3>
  <p>
    Feel free to contact me in case you have any questions or if you want to work with me.
  </p>
  <div class="info">
    <div class="row">
      <img src="assets/contact/phone.svg">
      <p>00 48 531 387 751</p>
    </div>
    <div class="row">
      <img src="assets/contact/sms.svg">
      <p>patrycja.musur@gmail.com</p>
    </div>
  </div>
  <app-footer></app-footer>
</div>
