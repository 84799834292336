<app-header (toggle)="this.navOpen = $event"></app-header>


<div class="holder" [ngClass]="navOpen ? 'nav' : ''">
  <div *ngIf="router.url === '/'" class="holder second">
    <div class="projects-holder">
      <h3>
        UX/UI designer, concept developer by profession
      </h3>
      <a routerLink="project/daisy/">
        <app-project-thumb
          text="How can we save our environment and make better shopping choices?"
          img="dd.jpg"
        ></app-project-thumb>
      </a>
      <a  routerLink="project/ethic">
        <app-project-thumb
          [reverse]="true"
          text="How can companies grow their business by acquiring certain certifications?"
          img="ei.jpg">
        </app-project-thumb>
      </a>
      <a routerLink="project/omnistrat">
        <app-project-thumb
          text="How can you lead and manage your organisation globally?"
          img="omni.jpg"
        ></app-project-thumb>
      </a>
      <a routerLink="project/givt">
        <app-project-thumb
          [reverse]="true"
          text="How to organise the process of receiving compensation for delayed flights?"
          img="givt_cm.jpg"
        ></app-project-thumb>
      </a>
      <a routerLink="project/givt2">
        <app-project-thumb
          text="How can back office application make managers work more efficient?"
          img="givt_pm.jpg"
        ></app-project-thumb>
      </a>

      <a   routerLink="project/insurance">
        <app-project-thumb

          [reverse]="true"
          text="Easier commute to work? Let’s match drivers with passengers"
          img="axa.jpg"
        ></app-project-thumb>
      </a>
      <a routerLink="project/manufacture">
        <app-project-thumb
          text="Flexible product to configure in various manufacturing industries"
          img="and.jpg"
        ></app-project-thumb>
      </a>
      <a   routerLink="project/imaread">
        <app-project-thumb

          [reverse]="true"
          [last]="true"
          text="How can digital study tool optimize learning experience?"
          img="imaread.jpg"
        ></app-project-thumb>
      </a>

    </div>
    <app-footer></app-footer>
  </div>

</div>
<div class="holder router">
  <router-outlet></router-outlet>
<!--  <app-footer *ngIf="router.url.includes('project')">-->

<!--  </app-footer>-->
</div>

