<div *ngIf="!isLoaded" class="lds-ring"><div></div><div></div><div></div><div></div></div>

<div class="project-holder desktop" [class.isLoad]="isLoaded">
  <div class="iframe-holder" >
    <iframe (load)="loadCallBack()" class="desktop"  width="100%" height="896" src="https://xd.adobe.com/embed/7c1ec3a3-08fd-4f94-aec2-58aac59d2a2f-294d/?fullscreen" frameborder="0" allowfullscreen></iframe>
  </div>

</div>
<div class="project-holder mobile" [class.isLoad]="isLoaded">
  <iframe (load)="loadCallBack()" width="100%" height="896" src="https://xd.adobe.com/embed/4c2dc9e3-ba8d-46ef-bf5c-523d6a256283-197e/?fullscreen" frameborder="0" allowfullscreen></iframe>
</div>
<app-see-more
  link="/project/omnistrat"
  link2="/project/givt"
  text="How can you lead and manage your organisation globally?"
  text2="How to organise the process of receiving compensation for delayed flights?"
  img2="givt_cm.jpg"
  img="omni.jpg"></app-see-more>
