<div class="project-thumb" [ngClass]="{'reverse' : reverse, 'last' : last}">
  <div class="img-holder">
    <img src="assets/project-thumb/{{img}}">
  </div>
  <div class="des-button">
    <div class="desc">
      {{text}}
    </div>
    <div class="button">
      <span>read more</span>
    </div>
  </div>
</div>
<app-footer></app-footer>
