<div *ngIf="!isLoaded" class="lds-ring"><div></div><div></div><div></div><div></div></div>

<div class="project-holder desktop" [class.isLoad]="isLoaded">
  <div class="iframe-holder" >
    <iframe (load)="loadCallBack()" class="desktop"  width="100%" height="896" src="https://xd.adobe.com/embed/7afce9db-b5d5-4659-b9a7-ce1d5846f14f-2faf/?fullscreen" frameborder="0" allowfullscreen></iframe>
  </div>

</div>
<div class="project-holder mobile" [class.isLoad]="isLoaded">
  <iframe (load)="loadCallBack()" width="100%" height="896" src="https://xd.adobe.com/embed/a119229e-0b9a-413d-a715-c97e4786566c-f44a/?fullscreen" frameborder="0" allowfullscreen></iframe>
</div>
<app-see-more
  link2="/project/imaread"
  link="/project/manufacture"
  text2="How can digital study tool optimize learning experience?"
  text="Flexible product to configure in various manufacturing industries"
  img="and.jpg"
  img2="imaread.jpg"></app-see-more>
