<div class="about-holder">
  <div class="inner">
    <img src="assets/about/me.png">
    <div class="desc">
      <h3>Hello!</h3>
      <p>I am Patrycja, UX/UI designer and concept developer, passionate about creating meaningful digital experiences. After four years in the capital of Scandinavia I currently live in Cracow. I have a background in concept development, multimedia design and communication from KEA Copenhagen School of Design and Technology.</p>
      <p>During last few years I was working on products from various industries including manufacturing, education, insurance, transportation, fashion and media. I design informational architecture, user experience and user interface, I co-hosted various workshops in different stages of product design, from concept creation to alterations in already working systems. I love seeing how products are both well functioning and well designed and I believe this is due to well done problem solving, involving end users and other team members into the process. From phases of product development I mostly enjoy system mapping and seeing how products come together.</p>
      <p>Personally I believe in karma, I am a movie freak, music lover and weightlifting passionate.</p>
    </div>
  </div>
  <app-footer></app-footer>
</div>
