<div *ngIf="!isLoaded" class="lds-ring"><div></div><div></div><div></div><div></div></div>

<div class="project-holder desktop" [class.isLoad]="isLoaded">
  <div class="iframe-holder" >
    <iframe (load)="loadCallBack()" class="desktop"  width="100%" height="896" src="https://xd.adobe.com/embed/ba6cd738-d19e-4dee-969a-5fa9f5c5f9cb-ef3f/?fullscreen" frameborder="0" allowfullscreen></iframe>
  </div>

</div>
<div class="project-holder mobile" [class.isLoad]="isLoaded">
  <iframe (load)="loadCallBack()" width="100%" height="896" src="https://xd.adobe.com/embed/18cf51b1-a950-4e1c-a6e0-a606861bb7cd-4d9c/?fullscreen" frameborder="0" allowfullscreen></iframe>
</div>
<app-see-more
  link="/project/insurance"
  link2="/project/manufacture"
  text="Easier commute to work? Let’s match drivers with passengers"
  text2="Flexible product to configure in various manufacturing industries"
  img2="and.jpg"
  img="axa.jpg"></app-see-more>

