<div *ngIf="!isLoaded" class="lds-ring"><div></div><div></div><div></div><div></div></div>

<div class="project-holder desktop" [class.isLoad]="isLoaded">
  <div class="iframe-holder" >
    <iframe (load)="loadCallBack()" class="desktop"  width="100%" height="896" src="https://xd.adobe.com/embed/40c53b48-d51a-4114-9cc2-babc1ac4e782-1596/?fullscreen" frameborder="0" allowfullscreen></iframe>
  </div>
</div>
<div class="project-holder mobile" [class.isLoad]="isLoaded">
  <iframe (load)="loadCallBack()" width="100%" height="896" src="https://xd.adobe.com/embed/6e9b06ec-330c-4779-b8b6-b2567ebb643d-3bc8/?fullscreen" frameborder="0" allowfullscreen></iframe>

</div>
<app-see-more
  link="/project/insurance"
  link2="/project/givt2"
  text="How can back office application make managers work more efficient?"
  text2="Easier commute to work? Let’s match drivers with passengers"
  img2="axa.jpg"
  img="givt_pm.jpg"></app-see-more>
